import React, { useMemo, useState, useCallback } from 'react';
import Timer from '../../../components/timer';
import { MEETING_TIME } from '../../../store/struct/entities/meeting';
import Dialog from '../../../components/dialog';
import Meeting from './meeting';
import Results from './results';
import Estimates from './estimates';

import styles from './index.module.scss';
import { branch } from 'baobab-react/higher-order';
import { meSelector } from '../../../store/struct/selectors';

const BTNS = {
  BACK: 'back',
  NEXT: 'next',
  SAVE: 'save',
};

const Step = ({ step, current, children }) => <div className={step === current ? '' : styles.hidden}>{children}</div>;

const MeetingDialog = ({ eventsIds, onEnd, currentUser, agentId }) => {
  const [step, setStep] = useState(0);
  const buttonsConfig = useMemo(() => {
    const btns = [];

    if (step > 0) {
      btns.push({
        id: BTNS.BACK,
        title: 'Назад',
      });
    }
    if (step < 2) {
      btns.push({
        id: BTNS.NEXT,
        title: 'Далее',
      });
    }
    if (step === 2) {
      btns.push({
        id: BTNS.SAVE,
        title: 'Сохранить',
      });
    }
    return btns;
  }, [step]);
  const [meetingState, setMeetingState] = useState(null);
  const [resultsState, setResultsState] = useState(null);
  const [estimatesState, setEstimatesState] = useState(null);

  const handleClick = useCallback(
    id => {
      if (id === BTNS.BACK) {
        setStep(step - 1);
      }
      if (id === BTNS.NEXT) {
        setStep(step + 1);
      }
      if (id === BTNS.SAVE) {
        onEnd({
          meetingState,
          resultsState,
          estimatesState,
        });
      }
    },
    [step, onEnd, meetingState, resultsState, estimatesState],
  );

  return (
    <Dialog
      title={
        <>
          <div>Встреча</div>
          <Timer time={MEETING_TIME} />
        </>
      }
      buttons={buttonsConfig}
      onClick={handleClick}
    >
      <Step step={0} current={step}>
        <Meeting onChange={setMeetingState} />
      </Step>
      <Step step={1} current={step}>
        <Results onChange={setResultsState} eventsIds={eventsIds} client={currentUser[0]} agentId={agentId} />
      </Step>
      <Step step={2} current={step}>
        <Estimates onChange={setEstimatesState} />
      </Step>
    </Dialog>
  );
};

export default branch(
  {
    currentUser: meSelector(),
  },
  MeetingDialog,
);
