//TODO Check stale struct properties after server update

const STRUCT = {
  ID: 'id',
  ARGUMENTS: 'receivedClientInfo',
  CLIENT_INFO: 'clientInfo',
  MEETING: 'meeting',
  PACKAGES: 'packages',
  REQUEST: 'request',
  RESOLUTION: 'resolution',
  SIX_IBS_MEETING_GRADES: 'sixIbsMeetingGrades',

  // TODO: CHANGE TEMP PARAM NAMES
  CHECK_FORM: 'check_form', // shows that medicine is included in a form by a pharmacologist
  CREATE_REQUEST: 'create_request', // amount of packages. A request have to be approved by another client
  APPROVE_REQUEST: 'approve_request', // the client approves request that is created by another client
  ADD_PATIENTS: 'add_patients', // amount of patients. Ordered by a client immediately. Converted to the packages
  CONTRACT: 'contract', // amount of packages. That is ordered by a client immediately.
  PRESCRIPT: 'prescript', // amount of patients. Ordered by a client immediately. Converted to the packages
  PRESCRIPT_RETAIL: 'prescript_retail', // amount of patients. Ordered by a client immediately. Converted to the packages
  PRESCRIPT_DISCOUNT: 'prescript_discount', // amount of patients. Ordered by a client immediately. Converted to the packages
};

export default STRUCT;
