import AGENT_STRUCT from '../entities/agent';
import ENTITY_STRUCT from '../entities';
import TOP_STRUCT from '../';
import CLIENT_STRUCT from '../entities/client';
import { getById } from '../../../utils';
import MEETING_STRUCT from '../entities/meeting';
import INVEST_STRUCT from '../entities/invest';
import Baobab from 'baobab';
import { CALCULATED_CLIENT_STRUCT } from './client';
import CALCULATED_MEETING_STRUCT from './meeting';
import GAME_STRUCT from '../entities/game';
import STATUS_STRUCT from '../entities/status';

const monkey = Baobab.monkey;

const CALCULATED_STRUCT = {
  // Calculated data
  GAMES: 'games',
  CURRENT_MEETINGS: 'curr_meetings',
  CURRENT_CLIENTS: 'curr_clients',
  GAMES_TEAMS: 'game_teams',
};

export default CALCULATED_STRUCT;

export const initialState = () => ({
  [CALCULATED_STRUCT.CURRENT_CLIENTS]: monkey(
    ['..', TOP_STRUCT.ENTITIES, ENTITY_STRUCT.CLIENT],
    ['..', TOP_STRUCT.ENTITIES, ENTITY_STRUCT.POSITION],
    ['..', TOP_STRUCT.ENTITIES, ENTITY_STRUCT.INSTITUTION],
    ['..', TOP_STRUCT.ENTITIES, ENTITY_STRUCT.MEETING],
    ['..', TOP_STRUCT.ENTITIES, ENTITY_STRUCT.INVEST],
    ['..', TOP_STRUCT.ENTITIES, ENTITY_STRUCT.EVENT],
    ['..', TOP_STRUCT.ENTITIES, ENTITY_STRUCT.ME, AGENT_STRUCT.ID],
    function(clients, posts, institution, meetings, invests, events, agentId) {
      return clients.map(client => {
        const { ...resClient } = client;

        resClient[CALCULATED_CLIENT_STRUCT.POSITION] = getById(posts, resClient[CLIENT_STRUCT.POSITION_ID]);
        resClient[CALCULATED_CLIENT_STRUCT.INSTITUTION] = getById(institution, resClient[CLIENT_STRUCT.INSTITUTION_ID]);

        const agentMeetings = getAgentMeetings(agentId, meetings);
        resClient[CALCULATED_CLIENT_STRUCT.MEETINGS] = agentMeetings.filter(
          meeting => meeting[MEETING_STRUCT.CUSTOMER_ID] === resClient[CLIENT_STRUCT.ID],
        );

        resClient[CALCULATED_CLIENT_STRUCT.INVESTS] = invests
          .filter(
            invest =>
              invest[MEETING_STRUCT.CUSTOMER_ID] === client[CLIENT_STRUCT.ID] &&
              invest[INVEST_STRUCT.REPRESENTATIVE_ID] === agentId,
          )
          .map(
            (
              invest, // invests object is not extensible
            ) => {
              const relatedEvent = getById(events, invest[INVEST_STRUCT.EVENT_ID]);
              return { ...invest, event: relatedEvent };
            },
          );

        return resClient;
      });
    },
  ),
  [CALCULATED_STRUCT.CURRENT_MEETINGS]: monkey(
    ['..', TOP_STRUCT.ENTITIES, ENTITY_STRUCT.ME, AGENT_STRUCT.ID],
    ['..', TOP_STRUCT.ENTITIES, ENTITY_STRUCT.MEETING],
    getAgentMeetings,
  ),
  [CALCULATED_STRUCT.GAMES]: monkey(
    ['..', TOP_STRUCT.ENTITIES, ENTITY_STRUCT.GAME],
    ['..', TOP_STRUCT.ENTITIES, ENTITY_STRUCT.STATUS],
    (games, status) => {
      if (!Array.isArray(games)) {
        return [];
      }

      return games.map(game => ({
        ...game,
        status: getById(status, game[GAME_STRUCT.STATUS_ID])[STATUS_STRUCT.TITLE],
      }));
    },
  ),
});

export function getAgentMeetings(agentId, meetings) {
  let currMeetings = meetings.filter(meeting => {
    // workaround. When any meeting with other agent is finished excess data is send to every agent, we have to filter it here
    return !meeting[MEETING_STRUCT.REPRESENTATIVE_ID] || meeting[MEETING_STRUCT.REPRESENTATIVE_ID] === agentId;
  });
  currMeetings = currMeetings.map(item => {
    let startPeriod = item.start.substr(11, 5);
    let endPeriod = item.end.substr(11, 5);
    const meeting = { ...item };

    meeting[CALCULATED_MEETING_STRUCT.TIME] = startPeriod + '-' + endPeriod;

    return meeting;
  });

  currMeetings = currMeetings.sort((a, b) => {
    let timeA = parseInt(a.time.split('-')[0].replace(':', '')),
      timeB = parseInt(b.time.split('-')[0].replace(':', ''));

    return timeA - timeB;
  });

  return currMeetings;
}
