import React, { Component } from 'react';
import { branch } from 'baobab-react/higher-order';

import { meSelector } from '../../../store/struct/selectors';
import TeamsRating from '../../teams-rating';

class Grading extends Component {
  render() {
    const { user } = this.props;

    if (!user) {
      return null;
    }

    return <TeamsRating />;
  }
}

export default branch(
  {
    user: meSelector(),
  },
  Grading,
);
