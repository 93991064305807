const STRUCT = {
  ID: 'id',
  ARGS_CNT: 'args_cnt',
  CLIENT_ID: 'client_id',
  INSTITUTION_ID: 'institution_id',
  PACKAGES: 'packages',
  POSITION_ID: 'position_id',
  RECV_ARGS: 'recv_args',
  RECV_ARGS_CNT: 'recv_args_cnt',
  RESOLUTION: 'resolution',
};

export default STRUCT;
