import React, { useMemo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { branch } from 'baobab-react/higher-order';

import { meSelector, sixIbsCategorySelector, sixIbsParamSelector } from '../../../../store/struct/selectors';
import SIX_IBS_CATEGORY_STRUCT from '../../../../store/struct/entities/sixIbsCategory';
import SIX_IBS_PARAM_STRUCT from '../../../../store/struct/entities/sixIbsParam';

import Table, { TYPES as TABLE_TYPES } from '../../../../components/table';

import styles from '../index.module.scss';
import forOwn from 'lodash.forown';

const DEFAULT_GRADE = 'NA';

const Estimates = ({ sixIbsCategory, sixIbsParam, onChange }) => {
  const tableData = sixIbsCategory.map((category, index) => {
    return {
      id: category[SIX_IBS_CATEGORY_STRUCT.ID],
      step: index + 1,
      name: category[SIX_IBS_CATEGORY_STRUCT.TITLE],
      data: sixIbsParam
        .filter(param => param[SIX_IBS_PARAM_STRUCT.CATEGORY_ID] === category[SIX_IBS_CATEGORY_STRUCT.ID])
        .map(param => {
          return {
            id: param[SIX_IBS_PARAM_STRUCT.ID],
            name: param[SIX_IBS_PARAM_STRUCT.TITLE],
          };
        }),
    };
  });
  const [state, setState] = useState(
    tableData.reduce((result, group) => {
      group.data.forEach(data => {
        result[data.id] = DEFAULT_GRADE;
      });

      return result;
    }, {}),
  );
  const tableConfig = useMemo(
    () => [
      {
        type: TABLE_TYPES.TEXT,
        getValue: () => '',
        min: true,
      },
      {
        type: TABLE_TYPES.TEXT,
        getValue: data => data.name,
      },
      {
        type: TABLE_TYPES.SELECT,
        onChange: (data, value) => setState({ ...state, [data.id]: value }),
        getValue: () => '',
        options: [
          { id: DEFAULT_GRADE, title: DEFAULT_GRADE },
          { id: '1', title: 1 },
          { id: '2', title: 2 },
          { id: '3', title: 3 },
        ],
        min: true,
      },
    ],
    [state],
  );

  const tableGroupsConfig = useMemo(
    () => [
      {
        type: TABLE_TYPES.TEXT,
        getValue: data => data.step,
        min: true,
      },
      {
        type: TABLE_TYPES.TEXT,
        getValue: data => data.name,
      },
      {
        type: TABLE_TYPES.TEXT,
        getValue: () => '',
        min: true,
      },
    ],
    [],
  );

  useEffect(() => {
    const sixIbsMeetingGrades = [];
    forOwn(state, (val, key) => {
      if (!isNaN(val)) {
        sixIbsMeetingGrades.push({
          grade: Number(val),
          sixIbsParam_id: Number(key),
        });
      }
    });
    onChange(sixIbsMeetingGrades);
  }, [state, onChange]);

  return (
    <Table
      className={styles.estimatesTable}
      headers={['Этап', 'Оценка', 'Значение']}
      config={tableConfig}
      groupsConfig={tableGroupsConfig}
      data={tableData}
    />
  );
};

Estimates.propTypes = {
  onChange: PropTypes.func.isRequired,
};

export default branch(
  {
    currentUser: meSelector(),
    sixIbsCategory: sixIbsCategorySelector(),
    sixIbsParam: sixIbsParamSelector(),
  },
  Estimates,
);
