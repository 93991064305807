import { calcWithCoef, FIELD_STRUCT, FIELDS } from './const';
import { TYPES as TABLE_TYPES } from '../../../../../components/table';
import REPORT_STRUCT from '../../../../../store/struct/entities/report';
import CLIENT_STRUCT from '../../../../../store/struct/entities/client';
import { getById } from '../../../../../utils';

export function getTableData(client) {
  return FIELDS.filter(field => client[field.id]);
}

function getApprovalValue(reports, clients, selectedReport, agentId, meetingClient) {
  const idToFilter = agentId || selectedReport[REPORT_STRUCT.REPRESENTATIVE_ID];
  let agentReports = reports.filter(
    report =>
      report[REPORT_STRUCT.REPRESENTATIVE_ID] === idToFilter &&
      report[REPORT_STRUCT.INSTITUTION_ID] === meetingClient[CLIENT_STRUCT.INSTITUTION_ID],
  );

  // if we show result we only need to process reports that was before selected report
  if (selectedReport) {
    agentReports = agentReports.filter(report => report[REPORT_STRUCT.ID] < selectedReport[REPORT_STRUCT.ID]);
  }

  // report with less ID completed before reports with bigger ID
  agentReports.sort((a, b) => a[REPORT_STRUCT.ID] - b[REPORT_STRUCT.ID]);

  for (let i = agentReports.length - 1; i >= 0; i--) {
    const report = agentReports[i];
    const client = getById(clients, agentReports[i][REPORT_STRUCT.CLIENT_ID]);

    // Meeting with client who approved request was after any meeting that require approval
    if (client[CLIENT_STRUCT.APPROVE_REQUEST] && report[REPORT_STRUCT.APPROVE_REQUEST]) {
      return 0;
    }

    // most recent meeting with client request
    if (report[REPORT_STRUCT.CREATE_REQUEST]) {
      return report[REPORT_STRUCT.CREATE_REQUEST];
    }
  }

  return 0;
}

export function getTableConfig({
  client,
  resultCoef,
  state,
  onChange,
  isResultDemonstration,
  reports,
  clients,
  selectedReport,
  agentId,
}) {
  const resultWithApproval = client[CLIENT_STRUCT.APPROVE_REQUEST];

  let valueToApprove;
  if (resultWithApproval) {
    valueToApprove = getApprovalValue(reports, clients, selectedReport, agentId, client);
  }
  const config = [
    {
      type: TABLE_TYPES.TEXT,
      getValue: fieldData => fieldData[FIELD_STRUCT.HEADER],
    },
    {
      getType: fieldData => fieldData[FIELD_STRUCT.TYPE] || TABLE_TYPES.INPUT,
      getValue: fieldData => state[fieldData[FIELD_STRUCT.CONTRIBUTE_TO]],
      getProps: fieldData => {
        let disabled;

        // disable dropdown if client has no requests for approval
        if (resultWithApproval && fieldData[FIELD_STRUCT.ID] === CLIENT_STRUCT.APPROVE_REQUEST) {
          disabled = !valueToApprove;
        }

        if (isResultDemonstration) {
          disabled = true;
        }

        return { ...fieldData[FIELD_STRUCT.PROPS], disabled };
      },
      onChange: (fieldData, val) => onChange(fieldData, val),
      getOptions: fieldData => fieldData[FIELD_STRUCT.OPTIONS],
      getPostfix: fieldData => (isResultDemonstration ? 'уп.' : fieldData[FIELD_STRUCT.POSTFIX]),
      getPrefix: fieldData => {
        if (!resultWithApproval) {
          return '';
        }
        const str = valueToApprove ? `${valueToApprove} уп.` : 'Заявки нет';
        return fieldData[FIELD_STRUCT.ID] === CLIENT_STRUCT.APPROVE_REQUEST ? str : '';
      },
    },
    {
      type: TABLE_TYPES.TEXT,
      getValue: fieldData => {
        const val = calcWithCoef({ fieldData, client, resultCoef, state });

        return !fieldData.thirdColumnRequired ? '' : `${Math.round(val)} уп.`;
      },
    },
  ];
  // meeting result demonstration is only shows calculated value with all coefficients so last column is not needed
  return isResultDemonstration ? config.slice(0, -1) : config;
}
