import React, { Component, createRef } from 'react';
import { branch } from 'baobab-react/higher-order';
import find from 'lodash.find';

import { createMeeting, removeMeeting } from '../../../store/struct/entities/meeting/actions';
import {
  currentClientsSelector,
  currentGameSelector,
  currMeetingsSelector,
  eventsSelector,
  investsSelector,
  meSelector,
  reportsSelector,
  timeslotsSelector,
} from '../../../store/struct/selectors';
import POSITION_STRUCT from '../../../store/struct/entities/position';
import INSTITUTION_STRUCT from '../../../store/struct/entities/institution';
import AGENT_STRUCT from '../../../store/struct/entities/agent';
import CLIENT_STRUCT from '../../../store/struct/entities/client';
import EVENT_STRUCT from '../../../store/struct/entities/event';
import GAME_STRUCT from '../../../store/struct/entities/game';
import REPORT_STRUCT from '../../../store/struct/entities/report';
import MEETING_STRUCT, { MEETING_STATUSES } from '../../../store/struct/entities/meeting';
import CALCULATED_MEETING_STRUCT from '../../../store/struct/calculated/meeting';
import Table, { TYPES as TABLE_TYPES } from '../../../components/table';
import Form, { PARAMS, TYPES as FORM_TYPES, TYPES } from '../../../components/form';
import { PARAMS as SELECT_PARAMS } from '../../../components/form/select';
import Dialog from '../../../components/dialog';
import AddIcon from '../../../components/Icons/add-icon/addIcon.jsx';
import { THEMES } from '../../../components/button';

import { getTimeRange } from '../../../utils/time';
import { createInvest } from '../../../store/struct/entities/invest/actions';
import { getById } from '../../../utils';
import ResultDialog from '../../client/resultDialog';
import { CALCULATED_CLIENT_STRUCT } from '../../../store/struct/calculated/client';
import StatusText from '../../../components/status-text/status-text';

import styles from './index.module.scss';
import commonStyles from '../../../styles/common.module.scss';

const HEADERS = ['Учреждение', 'Должность', 'ФИО', 'Инвестиции', 'Статус', 'Действие'];
export const INVEST_TABLE_HEADERS = ['Название', 'Стоимость (у.е)', 'Коэффициент', 'Штраф(у.е)', 'Выбрать'];
const KEY_NAME = 'key';
const SCHEDULED_MEETINGS_MAXIMUM_AMOUNT = 3;

const MEETING_TABLE_STRUCT = {
  ...CALCULATED_CLIENT_STRUCT,
  MEETING_INDEX: 'meetingIndex',
};

const getEntryMeetingTime = entry => {
  const meeting = entry[MEETING_TABLE_STRUCT.MEETINGS][entry[MEETING_TABLE_STRUCT.MEETING_INDEX]];
  const dateString = meeting[MEETING_STRUCT.START];

  return new Date(
    dateString.substring(0, 4),
    dateString.substring(5, 7) - 1,
    dateString.substring(8, 10),
    dateString.substring(11, 13),
    dateString.substring(14, 16),
  ).getTime();
};

class Meetings extends Component {
  state = {
    meetingDialogVisible: false,
    investDialogVisible: false,
    selectedReport: null,
    selectedEventId: null,
  };

  selectedCliendId = null;
  form = createRef();
  blockedTimes = [];

  saveMeeting = () => {
    const { user } = this.props;
    const { time } = this.form.current.getState();

    if (!time) {
      this.closeDialog();
      return;
    }

    let timeslot_id = time;
    if (time.id) timeslot_id = time.id;

    const data = {
      timeslot_id: timeslot_id,
      representative_id: user.id,
    };

    this.props.dispatch(createMeeting, data);
    this.closeDialog();
  };

  closeDialog = () => {
    this.setState({
      meetingDialogVisible: false,
      investDialogVisible: false,
      selectedReport: null,
      selectedEventId: false,
      errorFeedback: null,
    });
  };

  getFirstAvailableOption = options => {
    for (let i = 0; i < options.length; i++) {
      if (!options[i].disabled) {
        return options[i];
      }
    }

    return null;
  };

  addMeeting = data => {
    const options = this.getOptions(data.id);
    const availableOption = this.getFirstAvailableOption(options);

    this.formConfig = [
      {
        [PARAMS.ID]: 'time',
        [PARAMS.TITLE]: 'Время встречи:',
        [PARAMS.INITIAL_VALUE]: availableOption,
        [PARAMS.OPTIONS]: options,
        [PARAMS.TYPE]: TYPES.SELECT,
      },
      {
        [PARAMS.ID]: CALCULATED_CLIENT_STRUCT.NAME,
        [PARAMS.TITLE]: 'Фамилия Имя:',
        [PARAMS.TYPE]: FORM_TYPES.INPUT,
        [PARAMS.INITIAL_VALUE]: data[CALCULATED_CLIENT_STRUCT.NAME],
        [PARAMS.READONLY]: true,
      },
      {
        [PARAMS.ID]: CALCULATED_CLIENT_STRUCT.POSITION,
        [PARAMS.TITLE]: 'Должность:',
        [PARAMS.TYPE]: FORM_TYPES.INPUT,
        [PARAMS.INITIAL_VALUE]: `${data[CALCULATED_CLIENT_STRUCT.POSITION][POSITION_STRUCT.NAME]}, ${data[
          CALCULATED_CLIENT_STRUCT.INSTITUTION
        ][POSITION_STRUCT.NAME].toLowerCase()}`,
        [PARAMS.READONLY]: true,
      },
      {
        [PARAMS.ID]: POSITION_STRUCT.DESC,
        [PARAMS.TITLE]: 'Полномочия:',
        [PARAMS.TYPE]: FORM_TYPES.INPUT,
        [PARAMS.INITIAL_VALUE]: data[POSITION_STRUCT.DESC],
        [PARAMS.READONLY]: true,
      },
    ];
    this.formData = {
      [MEETING_STRUCT.ID]: null,
      with: data.id,
    };

    this.setState({ meetingDialogVisible: true });
  };

  removeMeeting = tableRowData => {
    const meetings = tableRowData[MEETING_TABLE_STRUCT.MEETINGS];
    const rowMeetingIndex = tableRowData[MEETING_TABLE_STRUCT.MEETING_INDEX];
    this.props.dispatch(removeMeeting, {
      timeslot_id: meetings[rowMeetingIndex][MEETING_STRUCT.ID],
    });
  };

  showMeetingResults = tableRowData => {
    const meetings = tableRowData[MEETING_TABLE_STRUCT.MEETINGS];
    const rowMeetingIndex = tableRowData[MEETING_TABLE_STRUCT.MEETING_INDEX];
    const relatedReport = find(
      this.props.reports,
      report => report[REPORT_STRUCT.MEETING_ID] === meetings[rowMeetingIndex][MEETING_STRUCT.MEETING_ID],
    );

    this.setState({
      selectedReport: relatedReport,
    });
  };

  getOptions = clientId => {
    const { timeslots } = this.props;

    const options = [];

    timeslots.forEach(item => {
      if (item.customer_id !== clientId) return;

      const time = getTimeRange(item.start, item.end);

      if (this.checkTime(time) !== 1) return;

      let flDisabled = item.meeting_id !== null;

      if (find(this.blockedTimes, item => item === time)) {
        flDisabled = true;
      }

      options.push({
        [SELECT_PARAMS.ID]: item.id,
        [SELECT_PARAMS.TITLE]: time,
        [SELECT_PARAMS.DISABLED]: flDisabled,
      });
    });

    return options;
  };

  applyInvest = () => {
    const { user: agent, game, clients, events } = this.props;
    const selectedClient = getById(clients, this.selectedCliendId);
    const selectedEvent = getById(events, this.state.selectedEventId);

    if (agent[AGENT_STRUCT.FINAL_BUDGET] < selectedEvent[EVENT_STRUCT.COST]) {
      this.setState({ errorFeedback: 'Недостаточно средств' });
      return;
    }
    this.props.dispatch(createInvest, {
      game_exercise_id: game[GAME_STRUCT.ID],
      customer_id: selectedClient[CALCULATED_CLIENT_STRUCT.ID],
      event_id: selectedEvent[EVENT_STRUCT.ID],
      representative_id: agent[AGENT_STRUCT.ID],
      team_id: agent[AGENT_STRUCT.TEAM_ID],
    });
    this.setState({
      selectedEventId: null,
    });
  };

  showInvestDialog = clientData => {
    this.selectedCliendId = clientData[CALCULATED_CLIENT_STRUCT.ID];

    const getInvestByEventData = eventData => {
      const currentClient = getById(this.props.clients, clientData[MEETING_TABLE_STRUCT.ID]);
      return (
        find(currentClient[CALCULATED_CLIENT_STRUCT.INVESTS], invest => {
          return invest.event[EVENT_STRUCT.ID] === eventData[EVENT_STRUCT.ID];
        }) || null
      );
    };

    this.tableInvestConfig = [
      {
        type: TABLE_TYPES.TEXT,
        getValue: eventData => eventData[EVENT_STRUCT.TITLE],
      },
      {
        type: TABLE_TYPES.TEXT,
        getValue: eventData => eventData[EVENT_STRUCT.COST],
      },
      {
        type: TABLE_TYPES.TEXT,
        getValue: eventData => eventData[EVENT_STRUCT.EFFECT],
      },
      {
        type: TABLE_TYPES.CONDITIONAL,
        getComponentIndex: eventData => {
          const investWasMade = getInvestByEventData(eventData);

          return Number(investWasMade && investWasMade.fine);
        },
        components: [
          {
            type: TABLE_TYPES.TEXT,
            getValue: () => '',
          },
          {
            type: TABLE_TYPES.TEXT,
            getProps: () => ({
              className: styles.fine,
            }),
            getValue: eventData => eventData[EVENT_STRUCT.FINE],
          },
        ],
      },
      {
        type: TABLE_TYPES.CONDITIONAL,
        getComponentIndex: eventData => {
          const investWasMade = getInvestByEventData(eventData);

          if (!investWasMade) {
            return 0;
          }

          if (investWasMade.fine) {
            return 2;
          }

          return 1;
        },
        components: [
          {
            type: TABLE_TYPES.CHECKBOX,
            onChange: (eventData, checkBoxState) => {
              this.setState({
                selectedEventId: checkBoxState ? eventData[EVENT_STRUCT.ID] : null,
                errorFeedback: null,
              });
            },
            getValue: eventData => {
              return this.state.selectedEventId === eventData[EVENT_STRUCT.ID];
            },
          },
          {
            type: TABLE_TYPES.TEXT,
            getValue: tableRowData => 'Применено',
          },
          {
            type: TABLE_TYPES.TEXT,
            getValue: tableRowData => 'Штраф',
          },
        ],
      },
    ];

    this.setState({ investDialogVisible: true });
  };

  tableMeetingsConfig = [
    {
      type: TABLE_TYPES.TEXT,
      getValue: tableRowData => tableRowData[CALCULATED_CLIENT_STRUCT.INSTITUTION][INSTITUTION_STRUCT.NAME],
    },
    {
      type: TABLE_TYPES.TEXT,
      getValue: tableRowData => tableRowData[CALCULATED_CLIENT_STRUCT.POSITION][POSITION_STRUCT.NAME],
    },
    {
      type: TABLE_TYPES.TEXT,
      getValue: tableRowData => tableRowData[CALCULATED_CLIENT_STRUCT.NAME],
    },
    {
      type: TABLE_TYPES.CONDITIONAL,
      // returns index of the desired component from the components property
      getComponentIndex: tableRowData => {
        return 0;
      },
      components: [
        [
          {
            type: TABLE_TYPES.TEXT,
            style: {
              display: 'inline-block',
              // minWidth: '50px',
            },
            getValue: tableRowData => {
              // returns a sum of used investments for the certain client
              return (
                tableRowData[CALCULATED_CLIENT_STRUCT.INVESTS].reduce((acc, invest) => {
                  const PROP = invest.fine ? EVENT_STRUCT.FINE : EVENT_STRUCT.COST;

                  return acc + invest.event[PROP];
                }, 0) + ' '
              );
            },
          },
          {
            type: TABLE_TYPES.BUTTON,
            onClick: this.showInvestDialog,
            getProps: () => ({
              className: styles.addBtn,
            }),
            render: AddIcon,
            theme: THEMES.PRIMARY,
          },
        ],
        {
          type: TABLE_TYPES.BUTTON,
          onClick: this.showInvestDialog,
          getProps: () => ({
            className: styles.addBtn,
          }),
          render: AddIcon,
          theme: THEMES.PRIMARY,
        },
      ],
    },
    {
      type: TABLE_TYPES.TEXT,
      getValue: tableRowData => {
        const meetings = tableRowData[MEETING_TABLE_STRUCT.MEETINGS];
        const rowMeetingIndex = tableRowData[MEETING_TABLE_STRUCT.MEETING_INDEX];
        if (rowMeetingIndex === null) {
          return '-';
        }

        const status = meetings[rowMeetingIndex][MEETING_STRUCT.STATUS];
        if (status === MEETING_STATUSES.inProgress) {
          return <StatusText status={status} text={'Текущая'} />;
        }

        let time = meetings[rowMeetingIndex][CALCULATED_MEETING_STRUCT.TIME];

        return <StatusText status={status} text={time.split('-')[0]} />;
      },
    },
    {
      type: TABLE_TYPES.CONDITIONAL,
      // returns index of the desired component from the components property
      getComponentIndex: tableRowData => {
        const meetingIndex = tableRowData[MEETING_TABLE_STRUCT.MEETING_INDEX];
        if (meetingIndex === null) {
          return 0;
        }
        const meetings = tableRowData[MEETING_TABLE_STRUCT.MEETINGS];
        const status = meetings[meetingIndex][MEETING_STRUCT.STATUS];

        const meetingStatusesMap = {
          [MEETING_STATUSES.done]: 2,
          [MEETING_STATUSES.inProgress]: 1,
          [MEETING_STATUSES.pending]: 1,
        };
        return meetingStatusesMap[status];
      },
      components: [
        {
          type: TABLE_TYPES.BUTTON,
          onClick: this.addMeeting,
          text: 'Назначить',
          theme: THEMES.PRIMARY,
          min: true,
          getProps: () => {
            const plannedMeetings = this.props.meetings.filter(
              meeting =>
                meeting[MEETING_STRUCT.STATUS] === MEETING_STATUSES.inProgress ||
                meeting[MEETING_STRUCT.STATUS] === MEETING_STATUSES.pending,
            ).length;
            return {
              disabled: plannedMeetings >= SCHEDULED_MEETINGS_MAXIMUM_AMOUNT,
            };
          },
        },
        {
          type: TABLE_TYPES.BUTTON,
          onClick: this.removeMeeting,
          text: 'Отменить',
          theme: THEMES.CANCEL,
          min: true,
          getProps: tableRowData => {
            const clientMeetings = tableRowData[MEETING_TABLE_STRUCT.MEETINGS];
            const rowMeetingIndex = tableRowData[MEETING_TABLE_STRUCT.MEETING_INDEX];
            return {
              disabled: clientMeetings[rowMeetingIndex][MEETING_STRUCT.STATUS] !== MEETING_STATUSES.pending,
            };
          },
        },
        {
          type: TABLE_TYPES.BUTTON,
          onClick: this.showMeetingResults,
          text: 'Результаты',
          theme: THEMES.SECONDARY,
          min: true,
        },
      ],
    },
  ];
  tableMeetingsHeaderConfig = [
    {
      props: { className: commonStyles.headerSelect },
    },
    {
      props: { className: commonStyles.headerSelect },
    },
  ];

  getDate = strTime => {
    const arrTime = strTime.split(':');
    const checkDate = new Date();

    checkDate.setHours(...arrTime);

    return checkDate;
  };

  checkTime = strDate => {
    const arrPeriod = strDate.split('-');
    const startTime = this.getDate(arrPeriod[0]).getTime();
    const endTime = this.getDate(arrPeriod[1]).getTime();

    const now = Date.now();

    if (now > endTime) return -1;
    if (now < startTime) return 1;
    return 0;
  };

  getBlockedTimes = () => {
    const { meetings = [], timeslots = [] } = this.props;

    this.blockedTimes = timeslots.filter(item => {
      if (!item.meeting_id) return false;

      // return meetings.find(meeting => meeting.id === item.meeting_id )
      return find(meetings, meeting => meeting.id === item.id);
    });
    // console.log(this.blockedTimes);
    this.blockedTimes = this.blockedTimes.map(item => getTimeRange(item.start, item.end));
  };

  getMeetingsData = () => {
    const endingEntries = [];
    const meetingEntries = this.props.clients.reduce((acc, client) => {
      const meetingsCount = client[CALCULATED_CLIENT_STRUCT.MEETINGS].length;

      for (let i = 0; i <= meetingsCount; i++) {
        const entry = {
          ...client,
          [KEY_NAME]: `${client[CLIENT_STRUCT.ID]}_${i}`,
          [MEETING_TABLE_STRUCT.MEETING_INDEX]: i < meetingsCount ? i : null,
        };

        // place records that allow to create meeting in the end of the table
        if (entry[MEETING_TABLE_STRUCT.MEETING_INDEX] !== null) {
          acc.push(entry);
        } else {
          endingEntries.push(entry);
        }
      }

      return acc;
    }, []);

    meetingEntries.sort((entry1, entry2) => getEntryMeetingTime(entry1) - getEntryMeetingTime(entry2));

    return meetingEntries.concat(endingEntries);
  };

  rowConfig = {
    getClassName: eventData => {
      const getInvestByEventData = eventData => {
        const currentClient = getById(this.props.clients, this.selectedCliendId);
        return (
          find(currentClient[CALCULATED_CLIENT_STRUCT.INVESTS], invest => {
            return invest.event[EVENT_STRUCT.ID] === eventData[EVENT_STRUCT.ID];
          }) || null
        );
      };

      const investWasMade = getInvestByEventData(eventData);

      if (this.state.selectedEventId === eventData[EVENT_STRUCT.ID]) {
        return styles.selected;
      }

      if (!investWasMade) {
        return '';
      }

      if (!investWasMade.fine) {
        return styles.success;
      }

      return styles.warning;
    },
  };

  render() {
    const { clients = [] } = this.props;
    let { user } = this.props;
    const { meetingDialogVisible, investDialogVisible, selectedReport } = this.state;

    if (!user) {
      return null;
    }

    this.getBlockedTimes();

    return (
      <>
        <div className={styles.container}>
          {clients.length ? (
            <Table
              className={styles.tableFullHeight}
              headers={HEADERS}
              config={this.tableMeetingsConfig}
              headerConfig={this.tableMeetingsHeaderConfig}
              keyName={KEY_NAME}
              data={this.getMeetingsData()}
              filters={[0, 1]}
            />
          ) : null}
        </div>
        {meetingDialogVisible && (
          <Dialog title='Назначение встречи' onSubmit={this.saveMeeting} onCancel={this.closeDialog}>
            <Form ref={this.form} config={this.formConfig} />
          </Dialog>
        )}
        {investDialogVisible && (
          <Dialog
            title='Инвестиции'
            cancelTitle='Закрыть'
            submitTitle='Применить'
            onCancel={this.closeDialog}
            onSubmit={this.applyInvest}
            errorFeedback={this.state.errorFeedback}
            submitDisabled={this.state.selectedEventId === null}
          >
            <Table
              headers={INVEST_TABLE_HEADERS}
              rowConfig={this.rowConfig}
              config={this.tableInvestConfig}
              data={this.props.events}
              // This prop is required only to force component update.
              investRecords={this.props.invests.length}
              selectedIventId={this.state.selectedEventId}
            />
          </Dialog>
        )}
        {selectedReport && <ResultDialog onCancel={this.closeDialog} selectedReport={selectedReport} />}
      </>
    );
  }
}

export default branch(
  {
    user: meSelector(),
    reports: reportsSelector(),
    invests: investsSelector(),
    events: eventsSelector(),
    timeslots: timeslotsSelector(),
    meetings: currMeetingsSelector(),
    clients: currentClientsSelector(),
    game: currentGameSelector(),
  },
  Meetings,
);
