const STRUCT = {
  GOAL: 'goal',
  PACKAGES: 'packages',
  PERCENT_ARGS: 'percent_args',
  PERCENT_PACKAGES: 'percent_packages',
  POSSIBLE_ARGS: 'possible_args',
  RECV_ARGS: 'recv_args',
  TEAM_ID: 'team_id',
};

export default STRUCT;
