import React from 'react';
import { branch } from 'baobab-react/higher-order';
import sumBy from 'lodash.sumby';
import find from 'lodash.find';

import { ICONS } from '../../../components/Icons';
import TopPanelIcon from '../../../components/Icons/top-panel-icon/topPanelIcon';

import { meSelector, myRatingSelector, teamsRatingSelector } from '../../../store/struct/selectors';
import USER_STRUCT from '../../../store/struct/entities/user';
import RATING_STRUCT from '../../../store/struct/entities/myRating';
import TEAM_RATING_STRUCT from '../../../store/struct/entities/teamRating';

import styles from './index.module.scss';

const Ratings = props => {
  const { user, teamsRating, myRating } = props;

  if (!user) {
    return null;
  }

  const teamRating =
    find(teamsRating, {
      [TEAM_RATING_STRUCT.TEAM_ID]: user[USER_STRUCT.TEAM_ID],
    }) || null;
  const packages = sumBy(myRating, rating => Number(rating[RATING_STRUCT.PACKAGES]));

  return (
    <div className={styles.ratings}>
      <TopPanelIcon icon={<ICONS.MoneyIcon width={12} height={14} />} title={'Стартовый бюджет/Текущий бюджет'}>
        {user && `${user[USER_STRUCT.STARTING_BUDGET]}/${user[USER_STRUCT.FINAL_BUDGET]}`}
      </TopPanelIcon>
      <TopPanelIcon icon={<ICONS.Star width={15} height={15} />} title={'Выполнение плана'}>
        {user && `${((packages * 100) / user[USER_STRUCT.GOAL]).toFixed(0)}`}%
      </TopPanelIcon>
      <TopPanelIcon icon={<ICONS.MarketIcon width={15} height={15} />} title={'Рейтинг'}>
        {(teamRating && teamRating[TEAM_RATING_STRUCT.PACKAGES]) || 0}|
        {(teamRating && teamRating[TEAM_RATING_STRUCT.GOAL]) || 0}
      </TopPanelIcon>
    </div>
  );
};

export default branch(
  {
    user: meSelector(),
    teamsRating: teamsRatingSelector(),
    myRating: myRatingSelector(),
  },
  Ratings,
);
