const STRUCT = {
  ID: 'result_id',
  APPOINTED_EVENTS: 'appointed_events',
  ARGUMENTS_TOTAL: 'args_cnt',
  ARGUMENTS_USED: 'recv_args_cnt',
  CLIENT_ID: 'client_id',
  MEETING_ID: 'meeting_id',
  GRADES: 'sixibgrade',
  REPRESENTATIVE_ID: 'representative_id',
  MEETING_RESULTS_ID: 'result_id',
  PLANNED_START: 'plannedStart',
  CREATE_REQUEST: 'create_request',
  APPROVE_REQUEST: 'approve_request',
  INSTITUTION_ID: 'institution_id',
};

export default STRUCT;
