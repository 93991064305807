import React, { useState, useCallback } from 'react';
import { branch } from 'baobab-react/higher-order';

import {
  eventsSelector,
  meSelector,
  agentsSelector,
  teamsSelector,
  investsSelector,
  meetingsSelector,
  reportsSelector,
} from '../../../store/struct/selectors';
import MEETING_STRUCT, { MEETING_STATUSES } from '../../../store/struct/entities/meeting';
import RESULT_STRUCT from '../../../store/struct/entities/meetingResult';
import AGENT_STRUCT from '../../../store/struct/entities/agent';
import CLIENT_STRUCT from '../../../store/struct/entities/client';
import INVEST_STRUCT from '../../../store/struct/entities/invest';
import EVENT_STRUCT from '../../../store/struct/entities/event';
import REPORT_STRUCT from '../../../store/struct/entities/report';

import Table, { TYPES as TABLE_TYPES } from '../../../components/table';
import MeetingDialog from './meetingDialog';
import { updateMeeting } from '../../../store/struct/entities/meeting/actions';
import { sendMeetingResult } from '../../../store/struct/entities/meetingResult/actions';
import { getById } from '../../../utils';
import { getTimeRange } from '../../../utils/time';

import styles from './index.module.scss';
import InvestDialog from '../investDialog';
import ResultDialog from '../resultDialog';
import { THEMES } from '../../../components/button';
import InfoIcon from '../../../components/Icons/info-icon/infoIcon';
import StatusText from '../../../components/status-text/status-text';
import PropTypes from 'prop-types';
import find from 'lodash.find';

const HEADERS = ['Представитель', 'Инвестиции (y.e)', 'Статус', 'Действие'];

const investFilterFn = meeting => invest =>
  invest[INVEST_STRUCT.REPRESENTATIVE_ID] === meeting[MEETING_STRUCT.REPRESENTATIVE_ID] &&
  invest[INVEST_STRUCT.CUSTOMER_ID] === meeting[MEETING_STRUCT.CUSTOMER_ID];
const mapInvests = propName => (meeting, invests) =>
  invests.filter(investFilterFn(meeting)).map(invest => invest[propName]);

export const getClientEventsIds = mapInvests(INVEST_STRUCT.EVENT_ID);
export const getClientInvestIds = mapInvests(INVEST_STRUCT.ID);

const Meetings = ({ agents, teams, events, invests, meetings, dispatch, reports, currentUser }) => {
  const currentClient = currentUser[0];
  // we have timeslots in the meetings state for now
  let filteredMeetings = meetings.filter(meeting => {
    return meeting[MEETING_STRUCT.REPRESENTATIVE_ID] !== null;
  });

  // also filter meetings of other clients which are broadcasted by the server
  if (currentClient) {
    filteredMeetings = filteredMeetings.filter(meeting => {
      return meeting[MEETING_STRUCT.CUSTOMER_ID] === currentClient[CLIENT_STRUCT.ID];
    });
  }

  const [selectedMeeting, setSelectedMeeting] = useState(null);
  const [investDialogVisible, setInvestDialogVisible] = useState(false);
  const [resultDialogVisible, setResultDialogVisible] = useState(false);

  // const agent = meeting ? getById(agents, meeting[MEETING_STRUCT.REPRESENTATIVE_ID]) : null;

  const [meetingStarted, setMeetingStarted] = useState(null);
  const onMeetingStart = useCallback(
    meetingData => {
      // Do not dispatch an event if the meeting starts in the second time.
      if (meetingData[MEETING_STRUCT.STATUS] !== MEETING_STATUSES.inProgress) {
        dispatch(updateMeeting, {
          id: meetingData[MEETING_STRUCT.MEETING_ID],
          timeslot_id: meetingData[MEETING_STRUCT.ID],
          // When meeting was started, agent can't remove this meeting
          started: true, // send flag of starting
        });
      }

      setMeetingStarted(true);
      setSelectedMeeting(meetingData);
    },
    [dispatch],
  );
  const onMeetingEnd = useCallback(
    ({ meetingState, resultsState, estimatesState }) => {
      const argumentsStructExpectedByServer = meetingState.map(argumentId => ({
        clientInfo: {
          id: argumentId,
        },
      }));
      dispatch(sendMeetingResult, {
        [RESULT_STRUCT.ARGUMENTS]: argumentsStructExpectedByServer,
        [RESULT_STRUCT.MEETING]: { id: selectedMeeting[MEETING_STRUCT.MEETING_ID] },
        ...resultsState,
        [RESULT_STRUCT.SIX_IBS_MEETING_GRADES]: estimatesState,
      });
      setSelectedMeeting(null);
      setMeetingStarted(false);
    },
    [selectedMeeting, dispatch],
  );

  const meetingsTableConfig = [
    {
      type: TABLE_TYPES.TEXT,
      getValue: data => {
        const userId = data[MEETING_STRUCT.REPRESENTATIVE_ID];
        const agent = getById(agents, userId);

        return agent && agent[AGENT_STRUCT.NAME];
      },
    },
    [
      {
        type: TABLE_TYPES.TEXT,
        style: {
          display: 'inline-block',
        },
        getValue: meetingData => {
          return getClientInvestIds(meetingData, invests).reduce((acc, investId) => {
            const invest = getById(invests, investId);
            const event = getById(events, invest[INVEST_STRUCT.EVENT_ID]);

            const cost = invest[INVEST_STRUCT.FINE] ? event[EVENT_STRUCT.FINE] : event[EVENT_STRUCT.COST];

            return acc + cost;
          }, 0);
        },
      },
      {
        type: TABLE_TYPES.BUTTON,
        onClick: meetingData => {
          setSelectedMeeting(meetingData);
          setInvestDialogVisible(true);
        },
        theme: THEMES.PRIMARY,
        getProps: () => ({
          className: styles.addBtn,
        }),
        render: InfoIcon,
      },
    ],
    {
      type: TABLE_TYPES.TEXT,
      getValue: data => {
        const time = getTimeRange(data[MEETING_STRUCT.START], data[MEETING_STRUCT.END]).split('-')[0];
        const status = data[MEETING_STRUCT.STATUS];

        return <StatusText text={time} status={status} />;
      },
    },
    {
      type: TABLE_TYPES.CONDITIONAL,
      getComponentIndex: currentMeeting => {
        switch (currentMeeting[MEETING_STRUCT.STATUS]) {
          case MEETING_STATUSES.pending:
          case MEETING_STATUSES.inProgress:
            return 0;
          case MEETING_STATUSES.done:
            return 1;
          default:
            return;
        }
      },
      components: [
        {
          type: TABLE_TYPES.BUTTON,
          onClick: onMeetingStart,
          text: 'Начать',
          theme: THEMES.PRIMARY,
          min: true,
        },
        {
          type: TABLE_TYPES.BUTTON,
          onClick: meetingData => {
            setSelectedMeeting(meetingData);
            setResultDialogVisible(true);
          },
          text: 'Результат',
          theme: THEMES.SECONDARY,
          min: true,
        },
      ],
    },
  ];

  const onCloseDialog = () => {
    setInvestDialogVisible(false);
    setResultDialogVisible(false);
    setSelectedMeeting(null);
  };

  let selectedReport;
  if (selectedMeeting) {
    selectedReport = find(
      reports,
      report => report[REPORT_STRUCT.MEETING_ID] === selectedMeeting[MEETING_STRUCT.MEETING_ID],
    );
  }

  return (
    <>
      {filteredMeetings.length ? (
        <Table
          className={styles.tableFullHeight}
          headers={HEADERS}
          data={filteredMeetings}
          config={meetingsTableConfig}
        />
      ) : (
        'На данный момент с Вами не запланировано ни одной встречи'
      )}

      {selectedMeeting && meetingStarted && (
        <MeetingDialog
          events={events}
          onEnd={onMeetingEnd}
          eventsIds={getClientEventsIds(selectedMeeting, invests)}
          agentId={selectedMeeting[MEETING_STRUCT.REPRESENTATIVE_ID]}
        />
      )}

      {selectedMeeting && investDialogVisible && (
        <InvestDialog onCancel={onCloseDialog} investIds={getClientInvestIds(selectedMeeting, invests)} />
      )}
      {resultDialogVisible && <ResultDialog onCancel={onCloseDialog} selectedReport={selectedReport} />}
    </>
  );
};

Meetings.propTypes = {
  currentUser: PropTypes.array.isRequired,
};
Meetings.defaultProps = {
  currentUser: [],
};

export default branch(
  {
    currentUser: meSelector(),
    agents: agentsSelector(),
    teams: teamsSelector(),
    events: eventsSelector(),
    invests: investsSelector(),
    meetings: meetingsSelector(),
    reports: reportsSelector(),
  },
  Meetings,
);
