import React, { useMemo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import includes from 'lodash.includes';

import ARGUMENT_STRUCT from '../../../../store/struct/entities/argument';

import { branch } from 'baobab-react/higher-order';
import { argumentsSelector } from '../../../../store/struct/selectors';
import Table, { TYPES as TABLE_TYPES } from '../../../../components/table';

import styles from '../../../../styles/common.module.scss';

const Meeting = ({ onChange, clientArguments }) => {
  const [state, setState] = useState([]);
  const rowConfig = useMemo(
    () => ({
      getClassName: data => (includes(state, data[ARGUMENT_STRUCT.ID]) ? styles.selected : ''),
    }),
    [state],
  );
  const tableConfig = useMemo(
    () => [
      {
        type: TABLE_TYPES.CHECKBOX,
        getValue: data => includes(state, data[ARGUMENT_STRUCT.ID]),
        getProps: data => ({
          id: `argument_${data[ARGUMENT_STRUCT.ID]}`,
        }),
        onChange: (data, val) => {
          let newState = state.slice();

          if (val) {
            newState.push(data[ARGUMENT_STRUCT.ID]);
          } else {
            newState.splice(state.indexOf(data[ARGUMENT_STRUCT.ID]), 1);
          }

          setState(newState);
        },
      },
      {
        type: TABLE_TYPES.LABEL,
        getValue: data => data[ARGUMENT_STRUCT.BRIEF],
        getProps: data => ({
          htmlFor: `argument_${data[ARGUMENT_STRUCT.ID]}`,
        }),
      },
    ],
    [state],
  );
  useEffect(() => {
    onChange(state);
  }, [state, onChange]);

  return <Table headers={['Выбрать', 'Аргументы']} rowConfig={rowConfig} config={tableConfig} data={clientArguments} />;
};

Meeting.propTypes = {
  onChange: PropTypes.func.isRequired,
};

export default branch(
  {
    clientArguments: argumentsSelector(),
  },
  Meeting,
);
