import React from 'react';
import { branch } from 'baobab-react/higher-order';

import { institutionsSelector, meSelector, positionsSelector } from '../../store/struct/selectors';
import USER_STRUCT from '../../store/struct/entities/user';
import POSITION_STRUCT from '../../store/struct/entities/position';
import INSTITUTION_STRUCT from '../../store/struct/entities/institution';
import { getById } from '../../utils';

import Tabs from '../tabs';
import Rules from './rules';
import Reports from '../reports';
import Meetings from './meetings';
import TeamsRating from '../teams-rating';
import { ICONS } from '../../components/Icons';
import TopPanelIcon from '../../components/Icons/top-panel-icon/topPanelIcon';

import styles from '../agent/index.module.scss';

const tabs = [
  {
    title: 'Правила',
    content: Rules,
    icon: ICONS.UnionIcon,
  },
  {
    title: 'Встречи',
    content: Meetings,
    icon: ICONS.MeetIcon,
  },
  {
    title: 'Отчет',
    content: Reports,
    icon: ICONS.MoneyIcon,
  },
  {
    title: 'Рейтинг',
    content: () => <TeamsRating />,
    icon: ICONS.MarketIcon,
  },
];

const ClientContainer = props => {
  const { positions, institutions } = props;

  const user = props.user && props.user[0];
  const header = user && (
    <TopPanelIcon icon={<ICONS.PersonIcon width={14} height={14} />} title={'Текущая должность'}>
      <span className={styles.position}>{getById(positions, user[USER_STRUCT.POSITION_ID])[POSITION_STRUCT.NAME]}</span>
      {', '}
      <span className={styles.institution}>
        {getById(institutions, user[USER_STRUCT.INSTITUTION_ID])[INSTITUTION_STRUCT.NAME]}
      </span>
    </TopPanelIcon>
  );

  return (
    <>
      <div className={styles.content}>
        <Tabs tabs={tabs} {...props} header={header} />
      </div>
    </>
  );
};

export default branch(
  {
    user: meSelector(),
    positions: positionsSelector(),
    institutions: institutionsSelector(),
  },
  ClientContainer,
);
