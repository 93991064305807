import React from 'react';
import { branch } from 'baobab-react/higher-order';

import { currentGameSelector, meSelector, agentsSelector } from '../../../store/struct/selectors';
import GAME_STRUCT from '../../../store/struct/entities/game';
import USER_STRUCT from '../../../store/struct/entities/user';

import Form, { PARAMS, TYPES as FORM_TYPES } from '../../../components/form';
import { SUBTYPES } from '../../../components/form/input';

import styles from './index.module.scss';

const Rules = props => {
  const { game, user } = props;

  if (!user) {
    return null;
  }

  return (
    <div className={`${styles.container} ${styles.containerBottomPadding}`}>
      <Form
        config={[
          {
            [PARAMS.ID]: 'game_name',
            [PARAMS.TITLE]: 'Название игры:',
            [PARAMS.INITIAL_VALUE]: game[GAME_STRUCT.NAME],
            [PARAMS.TYPE]: FORM_TYPES.INPUT,
            [PARAMS.READONLY]: true,
          },
          {
            [PARAMS.ID]: 'goal',
            [PARAMS.TITLE]: 'Целевые показатели в упаковках (индивидуальные):',
            [PARAMS.INITIAL_VALUE]: `${user[USER_STRUCT.GOAL]} упаковок`,
            [PARAMS.TYPE]: FORM_TYPES.INPUT,
            [PARAMS.SUBTYPE]: SUBTYPES.TEXTAREA,
            [PARAMS.READONLY]: true,
          },
          {
            [PARAMS.ID]: 'game_desc',
            [PARAMS.TITLE]: 'Цели и описание игры:',
            [PARAMS.INITIAL_VALUE]: game[GAME_STRUCT.DESC],
            [PARAMS.TYPE]: FORM_TYPES.INPUT,
            [PARAMS.SUBTYPE]: SUBTYPES.TEXTAREA,
            [PARAMS.READONLY]: true,
          },
          {
            [PARAMS.ID]: 'game_rules',
            [PARAMS.TITLE]: 'Возможности и ограничения:',
            [PARAMS.INITIAL_VALUE]: game[GAME_STRUCT.RULES],
            [PARAMS.TYPE]: FORM_TYPES.INPUT,
            [PARAMS.SUBTYPE]: SUBTYPES.TEXTAREA,
            [PARAMS.READONLY]: true,
          },
        ]}
      />
    </div>
  );
};

export default branch(
  {
    game: currentGameSelector(),
    user: meSelector(),
    agents: agentsSelector(),
  },
  Rules,
);
