import React, { useMemo } from 'react';
import { branch } from 'baobab-react/higher-order';

import { teamsRatingSelector, teamsSelector } from '../../store/struct/selectors';
import RATING_STRUCT from '../../store/struct/entities/teamRating';
import TEAM_STRUCT from '../../store/struct/entities/team';
import { getById } from '../../utils';

import Table, { TYPES as TABLE_TYPES } from '../../components/table';

import styles from './index.module.scss';

const HEADERS = ['Рейтинг', 'Представитель', 'Аргументы', 'Результат', 'Результат, %'];

const TeamsRating = props => {
  const { teamsRating, teams } = props;

  const tableConfig = useMemo(() => {
    return [
      {
        type: TABLE_TYPES.TEXT,
        getValue: (data, i) => i + 1,
      },
      {
        type: TABLE_TYPES.TEXT,
        getValue: data => getById(teams, data[RATING_STRUCT.TEAM_ID])[TEAM_STRUCT.NAME],
      },
      {
        type: TABLE_TYPES.TEXT,
        getValue: data => (data[RATING_STRUCT.PERCENT_ARGS] || 0) + '%',
      },
      {
        type: TABLE_TYPES.TEXT,
        getValue: data => data[RATING_STRUCT.PACKAGES] || 0,
      },
      {
        type: TABLE_TYPES.TEXT,
        getValue: data => (data[RATING_STRUCT.PERCENT_PACKAGES] || 0) + '%',
      },
    ];
  }, [teams]);

  return (
    <Table
      keyName={RATING_STRUCT.TEAM_ID}
      className={styles.tableWithHeader}
      headers={HEADERS}
      config={tableConfig}
      data={teamsRating}
    />
  );
};

export default branch(
  {
    teams: teamsSelector(),
    teamsRating: teamsRatingSelector(),
  },
  TeamsRating,
);
