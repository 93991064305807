import React from 'react';
import Tabs from '../tabs';
import { ICONS } from '../../components/Icons';
import Ratings from './ratings';
import Rules from './rules';
import Meetings from './meetings';
import Grading from './grading';

import styles from './index.module.scss';
import Reports from '../reports';

const tabs = [
  {
    title: 'Правила',
    content: Rules,
    icon: ICONS.UnionIcon,
  },
  {
    title: 'Встречи',
    content: Meetings,
    icon: ICONS.MeetIcon,
  },
  {
    title: 'Отчет',
    content: Reports,
    icon: ICONS.MoneyIcon,
  },
  {
    title: 'Рейтинг',
    content: Grading,
    icon: ICONS.MarketIcon,
  },
];

const AgentContainer = props => {
  return (
    <>
      <div className={styles.content}>
        <Tabs tabs={tabs} {...props} header={<Ratings {...props} />} />
      </div>
    </>
  );
};

export default AgentContainer;
